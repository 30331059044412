import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { environment } from "@env/environment";
import { AuthorizationGuard } from "@core/guards/authorization-guard.service";
import { AuthorizationComponent } from "@shared/components/authorization/authorization.component";
import { AuthGuard } from "@core/guards/auth-guard.service";
import { NoAccessComponent } from 'web-frontend-component-library/components/no-access';
import { PageNotFoundComponent } from 'web-frontend-component-library/components/page-not-found';
import { PageWrongComponent } from 'web-frontend-component-library/components/page-wrong';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  {
    path: 'main',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
    data: {
      preload: false
    }
  },
  {
    path: 'auth',
    canActivate: [AuthorizationGuard],
    component: AuthorizationComponent
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'nsi',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/admin-nsi/admin-nsi.module').then(m => m.AdminNsiModule)
  },
  {
    path: 'arm',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/arm/arm.module').then(m => m.ArmModule)
  },
  {
    path: 'task',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/task/task.module').then(m => m.TaskModule)
  },
  {
    path: 'personal-area',
    canActivate: [AuthGuard],
    loadChildren: () => import('@modules/personal-area/personal-area.module').then(m => m.PersonalAreaModule)
  },
  ...environment.production ? [] : [{
    path: 'test',
    loadChildren: () => import('./modules/test/test.module').then(m => m.TestModule),
    data: {
      preload: false
    }
  }],
  { path: 'no-access', component: NoAccessComponent },
  { path: 'page-wrong', component: PageWrongComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: 'page-not-found' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { preloadingStrategy: QuicklinkStrategy }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
