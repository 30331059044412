import { QuicklinkModule } from 'ngx-quicklink';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContextMenuModule } from 'ngx-contextmenu';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from "ng-click-outside";
import * as dayjs from 'dayjs';
import * as dayjsUtc from 'dayjs/plugin/utc';
import * as dayjsFormats from 'dayjs/plugin/customParseFormat';
import * as dayjsRu from 'dayjs/locale/ru';
import * as dayjsQuarter from 'dayjs/plugin/quarterOfYear';
import * as dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as dayjsDuration from 'dayjs/plugin/duration';
import * as dayjsArraySupport from 'dayjs/plugin/arraySupport';
import { AuthorizationComponent } from "@shared/components/authorization/authorization.component";
import { HeaderComponent } from "@shared/components/header/header.component";
import { AccordionItemModule } from 'web-frontend-component-library/components/accordion-item';
import { ActionMenuModule } from 'web-frontend-component-library/components/action-menu';
import { BasePageWithChildsModule } from 'web-frontend-component-library/components/base-page-with-childs';
import { BreadcrumbsModule } from 'web-frontend-component-library/components/breadcrumbs';
import { ButtonModule } from 'web-frontend-component-library/components/button';
import { CalendarModule } from 'web-frontend-component-library/components/calendar';
import { CheckboxModule } from 'web-frontend-component-library/components/checkbox';
import { CheckboxListModule } from 'web-frontend-component-library//components/checkbox-list';
import { ConfirmModalModule } from 'web-frontend-component-library/components/confirm-modal';
import { DownloadProgressModule } from 'web-frontend-component-library/components/download-progress';
import { EventLogCardModule } from 'web-frontend-component-library/components/event-log-card';
import { FilesListModule } from 'web-frontend-component-library/components/files-list';
import { InputModule } from 'web-frontend-component-library/components/input';
import { LockEditableModule } from 'web-frontend-component-library/components/lock-editable';
import { LoaderModule } from 'web-frontend-component-library/components/loader';
import { MenuModule } from 'web-frontend-component-library/components/menu';
import { MultiDictionaryModule } from 'web-frontend-component-library/components/multi-dictionary';
import { MultiSelectModule } from 'web-frontend-component-library/components/multi-select';
import { NoSelectStubModule } from 'web-frontend-component-library/components/no-select-stub';
import { NoAccessStubModule } from 'web-frontend-component-library/components/no-access-stub';
import { NsiDictionarySelectListModule } from 'web-frontend-component-library/components/nsi-dictionary-select-list';
import { PasswordInputModule } from 'web-frontend-component-library/components/password-input';
import { RadioboxListModule } from 'web-frontend-component-library/components/radiobox-list';
import { RoleAccessTreeModule } from 'web-frontend-component-library/components/role-access-tree';
import { RoundProgressBarModule } from 'web-frontend-component-library/components/round-progress-bar';
import { SearchFieldModule } from 'web-frontend-component-library/components/search-field';
import { SelectSearchListModule } from 'web-frontend-component-library/components/select-search-list';
import { SimpleDictionaryModule } from 'web-frontend-component-library/components/simple-dictionary';
import { SimpleSelectModule } from 'web-frontend-component-library/components/simple-select';
import { SwitchModule } from 'web-frontend-component-library/components/switch';
import { TabsModule } from 'web-frontend-component-library/components/tabs';
import { TextareaModule } from 'web-frontend-component-library/components/textarea';
import { ToastrModule } from 'web-frontend-component-library/components/toastr';
import { UniversalTreeModule } from 'web-frontend-component-library/components/universal-tree';
import { UniversalTreeItemModule } from 'web-frontend-component-library/components/universal-tree-item';
import { DisableControlModule } from 'web-frontend-component-library/directives/disable-control';
import { FormatDatePipeModule } from 'web-frontend-component-library/pipes/format-date';
import { IsVisiblePipeModule } from 'web-frontend-component-library/pipes/is-visible';
import { RouteParamDirectiveModule } from 'web-frontend-component-library/directives/route-param';
import { LogoComponent } from './components/logo/logo.component';
import { TerminalModule } from 'web-frontend-component-library/components/terminal';
import { PopupContainerModule } from 'web-frontend-component-library/components/popup-container';
import { FocusTrapDirective, FocusTrapModule } from 'web-frontend-component-library/directives/focus-trap';
import {RoleFunctionsBlockComponent} from "@shared/components/role-functions-block/role-functions-block.component";
import {TaskResultModalComponent} from "@shared/components/task-result-modal/task-result-modal.component";

dayjs.extend(dayjsQuarter);
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsFormats);
dayjs.extend(dayjsIsSameOrBefore);
dayjs.extend(dayjsIsSameOrAfter);
dayjs.extend(dayjsDuration);
dayjs.extend(dayjsArraySupport);
dayjs.locale(dayjsRu);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    TranslateModule,
    RouterModule,
    NgbModule,
    ContextMenuModule,
    ClickOutsideModule,
    QuicklinkModule,
    // lib
    AccordionItemModule,
    ActionMenuModule,
    BasePageWithChildsModule,
    BreadcrumbsModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    CheckboxListModule,
    ConfirmModalModule,
    DownloadProgressModule,
    EventLogCardModule,
    FilesListModule,
    InputModule,
    LoaderModule,
    LockEditableModule,
    MenuModule,
    MultiDictionaryModule,
    MultiSelectModule,
    NoSelectStubModule,
    NoAccessStubModule,
    NsiDictionarySelectListModule,
    PasswordInputModule,
    RadioboxListModule,
    RoleAccessTreeModule,
    RoundProgressBarModule,
    SearchFieldModule,
    SelectSearchListModule,
    SimpleDictionaryModule,
    SimpleSelectModule,
    SwitchModule,
    TabsModule,
    TextareaModule,
    ToastrModule,
    UniversalTreeModule,
    UniversalTreeItemModule,
    DisableControlModule,
    FormatDatePipeModule,
    IsVisiblePipeModule,
    RouteParamDirectiveModule,
    TerminalModule,
    PopupContainerModule,
    FocusTrapModule,
  ],
  declarations: [
    AuthorizationComponent,
    HeaderComponent,
    LogoComponent,
    TaskResultModalComponent,
    RoleFunctionsBlockComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    TextMaskModule,
    NgbModule,
    QuicklinkModule,
    ClickOutsideModule,
    ReactiveFormsModule,
    TranslateModule,
    ContextMenuModule,
    // lib
    AccordionItemModule,
    ActionMenuModule,
    BasePageWithChildsModule,
    BreadcrumbsModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    CheckboxListModule,
    ConfirmModalModule,
    DownloadProgressModule,
    EventLogCardModule,
    FilesListModule,
    InputModule,
    LoaderModule,
    LockEditableModule,
    MenuModule,
    MultiDictionaryModule,
    MultiSelectModule,
    NoSelectStubModule,
    NoAccessStubModule,
    NsiDictionarySelectListModule,
    PasswordInputModule,
    RadioboxListModule,
    RoleAccessTreeModule,
    RoundProgressBarModule,
    SearchFieldModule,
    SelectSearchListModule,
    SimpleDictionaryModule,
    SimpleSelectModule,
    SwitchModule,
    TabsModule,
    TextareaModule,
    ToastrModule,
    UniversalTreeModule,
    UniversalTreeItemModule,
    DisableControlModule,
    FormatDatePipeModule,
    IsVisiblePipeModule,
    RouteParamDirectiveModule,
    PopupContainerModule,
    FocusTrapDirective,
    // components
    AuthorizationComponent,
    HeaderComponent,
    LogoComponent,
    TaskResultModalComponent,
    RoleFunctionsBlockComponent
  ],
  entryComponents: [
    TaskResultModalComponent
  ]
})
export class SharedModule { }
