import { ACCESS_TREE_OBJECT_TYPE } from "web-frontend-component-library/enums";
import { IAccessTreeItem } from "web-frontend-component-library/interfaces";

/** Регулярное выражения для проверки корректности IP адреса */
export const REGEXP_IP = /([0-9]{1,3}[\.]){3}[0-9]{1,3}/;
export const code_pattern = /[A-Z_0-9]/;
export const ip4regexp: RegExp = /^(?!\.)((^|\.)([1-9]?\d|1\d\d|2(5[0-5]|[0-4]\d))){4}$/;
export const ip6regexp: RegExp = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

export const ACCESS_OBJECTS_KEY = 'Edm-AccessObjects';
export const ACCESS_RIGHTS_KEY = 'Edm-AccessRights';
export const USER_MENU_KEY = 'Edm-UserMenuKey';
export const MENU_OPENED_KEY = 'Edm-MenuOpenedKey';
export const USER_INFO_KEY = 'Edm-UserInfo';
export const REDIRECT_TO_KEY = 'Edm-RedirectTo';
export const AUTH_KEY = 'Edm-Auth';
export const SYSTEM_PARAMS_KEY = 'Edm-SystemParams';
export const PUBLIC_SYSTEM_PARAMS_KEY = 'Edm-PublicSystemParams';

export enum EDM_SYSTEM_PARAMETER_NAME {
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
}

export const MENU_URLS = {
  g_Catalog: '/nsi/dictionaries',
  g_Role: '/admin/settings-access/roles',
  g_User: '/admin/settings-access/users',
  g_System: '/admin/parameters/system-parameters',
  g_TaskSchedule: '/admin/parameters/task-schedule',
  g_EventLog: '/admin/monitoring/event-log',
  g_HelpSessionLog: '/admin/monitoring/remote-assistance-session-log',
  g_WebProxy: '/admin/parameters/web-proxy',
  g_Profile: '/personal-area/profile',
  g_PEventLog: '/personal-area/monitoring/event-log',
  g_ListARM: '/arm/arms',
  g_GroupARM: '/arm/arm-groups',
  g_SampleTask: '/task/task-samples',
  g_StandartSampleTask: '/task/standart-task-samples',
  g_JournalTaskARM: '/task/arm-task-journal',
  g_TaskURM: '/task/task-urm',
  g_EventLogARM: '/arm/arm-event-log',
  g_FileStorage: '/admin/file-storage',
  g_TaskScenarioDiagram: '/admin/task-scenario-diagram',
  g_ArmProperties: '/admin/arm-properties'
}

export const MENU_ICONS = {
  m_NSI: 'icon-menu_nsi',
  m_Admin: 'icon-menu_admin',
  m_PersPage: 'icon-menu_personal_page',
  m_ARM: 'icon-menu_arm',
  m_Task: 'icon-menu_contracts'
}

export const MENU_OPENED_URLS = [
  '/main',
  '/test'
];

export const MENU_ADDITIONAL: IAccessTreeItem[] = [
  {
    id: "m_PersPage",
    code: "m_PersPage",
    name: "Личный кабинет",
    objectType: ACCESS_TREE_OBJECT_TYPE.MENU,
    parentId: null,
    sortOrder: 1000,
  },
  {
    id: "g_Profile",
    code: "g_Profile",
    name: "Профиль",
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
    parentId: "m_PersPage",
    sortOrder: 10,
  },
  {
    id: "m_PEventsLog",
    code: "m_PEventsLog",
    name: "Журналы действий",
    objectType: ACCESS_TREE_OBJECT_TYPE.MENU,
    parentId: "m_PersPage",
    sortOrder: 20,
  },
  {
    id: "g_PEventLog",
    code: "g_PEventLog",
    name: "События",
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
    parentId: "m_PEventsLog",
    sortOrder: 10,
  }
];

export enum GRID_ID {
  "ADMIN.USERS" = "Edm-admin.users",
  "ADMIN.ROLES" = "Edm-admin.roles",
  "ADMIN.SYSTEM_PARAMETERS" = "Edm-admin.system_parameters",
  'ADMIN.WEB_PROXY' = 'Edm-admin.web_proxy',
  'ADMIN.WEB_PROXY_JOURNAL' = 'Edm-admin.web_proxy_journal',
  "ADMIN.TASK_SCHEDULE" = "Edm-admin.task_schedule",
  "EVENT_LOG" = "Edm.event_log",
  "REMOTE_ASSISTANCE_SESSIONS_LOG" = "Edm.remote-assistance_sessions_log",
  "ADMIN.FILE_STORAGE" = "Edm-admin.file_storage",
  "ADMIN.TASK_SCENARIO_DIAGRAM" = "Edm-admin.task_scenario_diagram",
  'PERSONAL_AREA.MONITORING.EVENT_LOG_JOURNAL' = 'Edm.personal_area.monitoring.event_log_journal',
  "ARMS" = "Edm-arm.arms",
  "ARMS.PO_LIST" = "Edm-arm.arms.po_list",
  "ARMS.INVENTORY_SERVICE" = "Edm-arm.arms.inventory_service",
  "ARMS.INVENTORY_TASK_SCHEDULE" = "Edm-arm.arms.inventory_task_schedule",
  "ARMS.INVENTORY_PROCESS" = "Edm-arm.arms.inventory_process",
  "ARMS.INVENTORY_LOCAL_GROUP" = "Edm-arm.arms.inventory_local_group",
  "ARMS.INVENTORY_LOCAL_USER" = "Edm-arm.arms.inventory_local_user",
  "ARMS.TASK_JOURNAL" = "Edm-arm.arms.task_journal",
  "ARMS.STATE_LOG" = "Edm-arm.arms.state_log",
  "ARMS.EVENT_LOG" = "Edm-arm.arms.event_log",
  "ARMS.ARM_EVENT_LOG" = "Edm-arm.arms.arm_event_log",
  "ARM_GROUPS" = "Edm-arm.arm_groups",
  "ARM_GROUPS.ARM_LIST" = "Edm-arm.arm_groups.arm_list",
  "ARM_GROUPS.USER_LIST" = "Edm-arm.arm_groups.user_list",
  "TASK_SAMPLES" = "Edm-arm.task_samples",
  "STANDART_TASK_SAMPLES" = "Edm-arm.standart_task_samples",
  "ARM_TASK_JOURNAL" = "Edm-arm.arm_task_journal",
  "ARM_EVENT_LOG" = "Edm-arm.arm_event_log",
  "ARM_REMOTE_SESSION_LOG" = "Edm-arm.arm_remote_session_log",
  "ADMIN.ARM_PROPERTIES" = "Edm-admin.arm_properties",
  "TASK_URM" = "Edm-arm.task_urm",
  "TASK_URM.GROUPS" = "Edm-arm.task_urm.groups",
  "TASK_URM.DEVICES" = "Edm-arm.task_urm.devices",
  "TASK_URM.EVENT_LOG" = "Edm-arm.task_urm.event_log",
  "TASK_URM.TASK_JOURNAL" = "Edm-arm.task_urm.task_journal",
  "TASK_URM.TASK_JOURNAL.DETAIL" = "Edm-arm.task_urm.task_journal.detail",
}

export enum USER_ACCESS {
  "ADMIN" = "m_Admin",
  // Роли
  "ROLE" = "g_Role",
  "ROLE.card" = "c_Role",
  "ROLE.card_add" = "c_Role.cf_add",
  "ROLE.card_edit" = "c_Role.cf_edit",
  "ROLE.card_del" = "c_Role.cf_del",
  // Пользователи
  "USER" = "g_User",
  "USER.card" = "c_User",
  "USER.card_edit" = "c_User.cf_edit",
  // Системные параметры
  "SYSTEM" = "g_System",
  "SYSTEM.card" = "c_System",
  "SYSTEM.card_edit" = "c_System.cf_edit",
  // Расписание запуска задач
  "TASKSCHEDULE" = "g_TaskSchedule",
  "TASKSCHEDULE.card" = "c_TaskSchedule",
  "TASKSCHEDULE.card_edit" = "c_TaskSchedule.cf_Edit",
  // Общий журнал событий
  "EVENT_LOG" = "g_EventLog",
  // Журнал сеансов удаленного помощника
  "REMOTE_ASSISTANCE_SESSION_LOG" = "g_HelpSessionLog",
  // НСИ
  "NSI_CATALOG" = "g_Catalog",
  // Веб прокси
  'ADMIN.WEB_PROXY' = 'g_WebProxy',
  'ADMIN.WEB_PROXY.CARD' = 'c_WebProxy',
  'ADMIN.WEB_PROXY.CARD.JOURNAL' = 'c_WebProxy.ct_Journal',
  'ADMIN.WEB_PROXY.CARD.ADD' = 'c_WebProxy.cf_add',
  'ADMIN.WEB_PROXY.CARD.EDIT' = 'c_WebProxy.cf_edit',
  'ADMIN.WEB_PROXY.CARD.DELETE' = 'c_WebProxy.cf_delete',
  'ADMIN.WEB_PROXY.CARD.ADD_ROLE' = 'c_WebProxy.cf_add_role',
  'ADMIN.WEB_PROXY.CARD.DELETE_ROLE' = 'c_WebProxy.cf_delete_role',
  // Хранилище файлов
  'ADMIN.FILE_STORAGE' = 'g_FileStorage',
  // Схема сценариев задач для АРМ
  'ADMIN.TASK_SCENARIO' = 'g_TaskScenarioDiagram',
  'ADMIN.TASK_SCENARIO.card' = 'с_TaskScenarioDiagram',
  'ADMIN.TASK_SCENARIO.card.add' = 'с_TaskScenarioDiagram.cf_Add',
  'ADMIN.TASK_SCENARIO.card.edit' = 'с_TaskScenarioDiagram.cf_Edit',
  'ADMIN.TASK_SCENARIO.card.delete' = 'с_TaskScenarioDiagram.cf_Delete',

  'ARM' = 'm_ARM',
  // Список АРМ
  'ARM_LIST' = 'g_ListARM',
  'ARM_LIST.card' = 'c_ARM',
  'ARM_LIST.card.po_list' = 'c_ARM.ct_POList',
  'ARM_LIST.card.task_journal' = 'c_ARM.ct_JournalTask',
  'ARM_LIST.card.journal' = 'c_ARM.ct_Journal',
  'ARM_LIST.card.add' = 'c_ARM.cf_Add',
  'ARM_LIST.card.edit' = 'c_ARM.cf_Edit',
  'ARM_LIST.card.archive' = 'c_ARM.cf_Archive',
  'ARM_LIST.card.block' = 'c_ARM.cf_Block',
  'ARM_LIST.card.active' = 'c_ARM.cf_Active',
  'ARM_LIST.card.add_task' = 'c_ARM.cf_AddTask',
  'ARM_LIST.card.cancel_task' = 'c_ARM.cf_CancelTask',
  'ARM_LIST.card.repeat_task' = 'c_ARM.cf_RepeatTask',
  'ARM_LIST.card.remote_desktop' = 'c_ARM.cf_RemoteDesktop',
  'ARM_LIST.card.silent-remote_desktop' = 'c_ARM.cf_RemoteDesktopSilentMode',
  // Группа АРМ
  'ARM_GROUP' = 'g_GroupARM',
  'ARM_GROUP.card' = 'c_GroupARM',
  'ARM_GROUP.card.add' = 'c_GroupARM.cf_Add',
  'ARM_GROUP.card.edit' = 'c_GroupARM.cf_Edit',
  'ARM_GROUP.card.delete' = 'c_GroupARM.cf_Delete',
  'ARM_GROUP.card.add_arm' = 'c_GroupARM.cf_AddGroup',
  'ARM_GROUP.card.delete_arm' = 'c_GroupARM.cf_DeleteGroup',

  'TASK' = 'm_Task',
  // Пользовательские шаблоны
  'TASK_SAMPLE' = 'g_SampleTask',
  'TASK_SAMPLE.card' = 'c_SampleTask',
  'TASK_SAMPLE.card.add' = 'c_SampleTask.cf_Add',
  'TASK_SAMPLE.card.edit' = 'c_SampleTask.cf_Edit',
  'TASK_SAMPLE.card.delete' = 'c_SampleTask.cf_Delete',
  'TASK_SAMPLE.card.archive' = 'c_SampleTask.cf_Archive',
  'TASK_SAMPLE.card.restore' = 'c_SampleTask.cf_Draft',
  'TASK_SAMPLE.card.active' = 'c_SampleTask.cf_Active',
  // Стандартные шаблоны
  'STANDART_TASK_SAMPLE' = 'g_StandartSampleTask',
  'STANDART_TASK_SAMPLE.card' = 'c_StandartSampleTask',
  'STANDART_TASK_SAMPLE.card.add' = 'c_StandartSampleTask.cf_Add',
  'STANDART_TASK_SAMPLE.card.edit' = 'c_StandartSampleTask.cf_Edit',
  'STANDART_TASK_SAMPLE.card.delete' = 'c_StandartSampleTask.cf_Delete',
  'STANDART_TASK_SAMPLE.card.archive' = 'c_StandartSampleTask.cf_Archive',
  'STANDART_TASK_SAMPLE.card.restore' = 'c_StandartSampleTask.cf_Draft',
  'STANDART_TASK_SAMPLE.card.active' = 'c_StandartSampleTask.cf_Active',
  // Журнал запусков задач
  'ARM_TASK_JOURNAL' = 'g_JournalTaskARM',
  // Задачи для УРМ
  'TASK_URM' = 'g_TaskURM',
  'TASK_URM.card' = 'c_TaskURM',
  'TASK_URM.card.test' = 'c_TaskURM.ct_Test',
  'TASK_URM.card.devices_group' = 'c_TaskURM.ct_DevicesGroup',
  'TASK_URM.card.event_log' = 'c_TaskURM.ct_JournalEvent',
  'TASK_URM.card.add' = 'c_TaskURM.cf_AddTask',
  'TASK_URM.card.edit' = 'c_TaskURM.cf_EditTask',
  'TASK_URM.card.delete' = 'c_TaskURM.cf_DeleteTask',
  'TASK_URM.card.active' = 'c_TaskURM.cf_Active',
  'TASK_URM.card.inactive' = 'c_TaskURM.cf_InActive',
  'TASK_URM.card.archive' = 'c_TaskURM.cf_Archive',
  'TASK_URM.card.restore' = 'c_TaskURM.cf_RestoreArchive',
  'TASK_URM.card.edit_sample' = 'c_TaskURM.cf_EditSample',
  'TASK_URM.card.add_device' = 'c_TaskURM.cf_AddDevice',
  'TASK_URM.card.add_group' = 'c_TaskURM.cf_AddGroup',
  'TASK_URM.card.delete_device' = 'c_TaskURM.cf_DeleteDevice',
  'TASK_URM.card.delete_group' = 'c_TaskURM.cf_DeleteGroup',
  'TASK_URM.card.cancel' = 'c_TaskURM.cf_CancelTask',
  'TASK_URM.card.repeat' = 'c_TaskURM.cf_RepeatTask',
  // Журнал событий от АРМ
  'ARM_EVENT_LOG' = 'g_EventLogARM',
  // Настройки агентов
  'ADMIN.ARM_PROPERTIES' = 'g_ArmProperties',
  'ADMIN.ARM_PROPERTIES.card' = 'с_ArmProperties',
  'ADMIN.ARM_PROPERTIES.card.edit' = 'c_ArmProperties.cf_Edit'
}

export enum RoleType {
  COMMON = 'COMMON',
  PERSONAL = 'PERSONAL',
  NO_ROLE = 'NO_ROLE',
}

export enum TaskScheduleType {
  DATABASE = "DATABASE",
  BACKEND = "BACKEND"
}

export enum Microservice {
  MAIN = 'WS_WEB_BACKEND'
}

export enum ArmStateCode {
  CREATED = 'CREATED', // Создан
  NEW = 'NEW', // Новый
  ACTIVE = 'ACTIVE', // Активен
  ARCHIVE = 'ARCHIVE', // Архив
  BLOCKED = 'BLOCKED' // Заблокирован
}

export enum ArmConnectStateCode {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE'
}
export enum EArmConnectionStateIcon {
  ONLINE = 'icon-online',
  OFFLINE = 'icon-offline'
}

export enum ArmStateIcon {
  CREATED = 'icon-new', // Создан
  NEW = 'icon-new', // Новый
  ACTIVE = 'icon-good', // Активен
  ARCHIVE = 'icon-in_archive', // Архив
  BLOCKED = 'icon-denied' // Заблокирован
}

export enum TaskSampleStateCode {
  NEW = 'NEW', // Новый
  DRAFT = 'DRAFT', // Черновик
  ACTIVE = 'ACTIVE', // Активен
  ARCHIVE = 'ARCHIVE', // Архив
}

export enum TaskSampleStateIcon {
  NEW = 'icon-new', // Новый
  DRAFT = 'icon-registered', // Черновик
  ACTIVE = 'icon-good', // Активен
  ARCHIVE = 'icon-in_archive', // Архив
}

export enum TaskTerminalCommand {
  RESULT = 'TASK_ANSIBLE_RESULT',
  CANCEL = 'TASK_ANSIBLE_FINISH'
}

export enum TaskScenarioDiagramSettingCode {
  PRODUCT_ID = 'PRODUCT_ID',
  SCRIPT_PARAMETER = 'SCRIPT_PARAMETER',
  FILE = 'FILE'
}

export enum TaskUrmStateCode {
  CREATED = 'CREATED', // Новый
  TASK_DRAFT = 'TASK_DRAFT', // Черновик
  TASK_ACTIVE = 'TASK_ACTIVE', // Активен
  TASK_ARCHIVE = 'TASK_ARCHIVE', // Архив
  TASK_INACTIVE = 'TASK_INACTIVE' // Неактивна
}

export enum TaskUrmStateIcon {
  CREATED = 'icon-new', // Новый
  TASK_DRAFT = 'icon-registered', // Черновик
  TASK_ACTIVE = 'icon-good', // Активен
  TASK_ARCHIVE = 'icon-in_archive', // Архив
  TASK_INACTIVE = 'icon-dismantled_state' // Неактивна
}

export enum TaskUrmScheduleType {
  ONCE = 'ONCE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export enum TaskUrmJournalStateCode {
  CREATED = 'CREATED', // Создана
  TASK_LOG_WAIT = 'TASK_LOG_WAIT', // Ожидает выполнения
  TASK_LOG_IN_QUEUE = 'TASK_LOG_IN_QUEUE', // В очереди на выполнение
  TASK_LOG_IN_PROGRESS = 'TASK_LOG_IN_PROGRESS', // Выполняется
  TASK_LOG_COMPLETE = 'TASK_LOG_COMPLETE', // Выполнена
  TASK_LOG_PARTIALLY_COMPLETE = 'TASK_LOG_PARTIALLY_COMPLETE', // Выполнена частично
  TASK_LOG_ERROR = 'TASK_LOG_ERROR', // Ошибка
  TASK_LOG_CANCEL = 'TASK_LOG_CANCEL', // Отменена
}

export enum TaskUrmJournalStateIcon {
  CREATED = 'icon-registered', // Создана
  TASK_LOG_WAIT = 'icon-in_wait', // Ожидает выполнения
  TASK_LOG_IN_QUEUE = 'icon-in_queue', // В очереди на выполнение
  TASK_LOG_IN_PROGRESS = 'icon-service', // Выполняется
  TASK_LOG_COMPLETE = 'icon-good', // Выполнена
  TASK_LOG_PARTIALLY_COMPLETE = 'icon-success_with_error', // Выполнена частично
  TASK_LOG_ERROR = 'icon-error_warn', // Ошибка
  TASK_LOG_CANCEL = 'icon-dismantled_state', // Отменена
}
